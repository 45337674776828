import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";

export default function Quotation() {
  return (
    <div>
      <Navigation/>
      <section id="services">
        <div className="container">
          <div
            className="heading wow fadeInUp"
            data-wow-duration="1000ms"
            data-wow-delay="300ms"
          >
            <div className="row">
              <div className="col-sm-8">
                <center>
                  <h2>Innovative Solutions</h2>
                  <p>Together we, can change the way of business</p>
                  <br />
                  <a
                    href="http://www.1shodh.com/article/affordable-website-development"
                    target="_blank"
                  >
                    <img
                      src="http://www.1shodh.com/assets/img/article/weboffer.webp"
                      className="img-rounded"
                      alt="HP-Transcoders-monsoon-offer-Rs-2999-only"
                      width="80%"
                      height="80%"
                    />
                  </a>
                </center>
              </div>
              <div className=" col-sm-4 " style={{"margin-top":"150px"}}>
                <center>
                  <a
                    href="https://wa.me/919921839702?text=I'm%20interested%20to%20get%20quoation%20for%20service"
                    target="_blank"
                  >
                    <img
                      src="assets/images/whatsapp-button.webp"
                      alt="hp-transcoders-support"
                      height=""
                      width="200px"
                    />
                  </a>
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footers/>
    </div>
  );
}
