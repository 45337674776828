import React from "react";
import { useRef } from "react";
import $ from "jquery";
import emailjs from '@emailjs/browser';

export default function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0d6kk8r', 'template_7ilzrbd', form.current, 'YOUR_USER_ID')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  let service, services;
  service = [
    "Logo designing",
    "Google ads (search engine marketing)",
    "Facebook advertisement",
    "Digital marketing",
    "Email marketing",
    "Web designing",
    "Web development",
    "Customized web app",
    "Wordpress website designing",
    "E-commerce website",
    "Android apps",
    "Landing page designing",
    "Website AMC",
    "Online Reputation Management",
    "Graphics designing",
    "Business cards",
  ];
  $(document).ready(function () {
    service.sort();
    for (var i = 0; i < service.length; i++) {
      services += `<option value='${service[i]}'>${service[i]}</option>`;
    }
    $("#requirement").append(services);
    // $("form").on("submit", function (e) {
    //   e.preventDefault();
    //   let data = $("form").serialize();
    //   // alert(data);

    //   if (
    //     $("#name").val() == "" ||
    //     $("#email").val() == "" ||
    //     $("#subject").val() == "" ||
    //     $("#message").val() == "" ||
    //     $("#mobile").val() == ""
    //   ) {
    //     //alert('blank');
    //   } else {
    //     sendEmail();
    //     // alert("hi");
    //   }
    // });
   
  });
  return (
    <>
      <section id="contact">
        <div id="contact-us" className="parallax">
          <div className="container">
            <div className="row">
              <div
                className="heading text-center col-sm-8 col-sm-offset-2 wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="300ms"
              >
                <h2>Contact Us</h2>
                <p>We will get back to you as soon as possible</p>
              </div>
            </div>
            <div
              className="contact-form wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="600ms"
            >
              <div className="row">
                <div className="col-sm-6">
                  <form
                    id="main-contact-form"
                    name="contact-form"
                   
                    ref={form} onSubmit={sendEmail}
                  >
                    <div
                      className="row  wow fadeInUp"
                      data-wow-duration="1000ms"
                      data-wow-delay="300ms"
                    >
                      <div>
                        <p className="text-success">
                          <i className="fa fa-spinner fa-spin"></i>{" "}
                        </p>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Name"
                            required="required"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email Address"
                            required="required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <select
                        name="requirement"
                        id="requirement"
                        className="form-control"
                        required="required"
                      >
                        <option>SELECT Service</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Subject"
                        required="required"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="4"
                        placeholder="Enter your message"
                        required="required"
                      ></textarea>
                    </div>
                    {/* <div className="form-group">
                      <div
                        class="g-recaptcha"
                        data-sitekey="6LeZY6kZAAAAADD4KvgsZ-l3Jy0MAyier6MUpHnb"
                      ></div>
                    </div> */}

                    <div className="form-group">
                      <input
                        type="submit"
                        className="btn-submit"
                        value="Send Now"
                      />
                    </div>
                  </form>
                </div>
                <div className="col-sm-6">
                  <div
                    className="contact-info wow fadeInUp"
                    data-wow-duration="1000ms"
                    data-wow-delay="300ms"
                  >
                    <p>
                      Warning for spammer: This website is running under
                      security of Transcoders Robots, any misconduct from your
                      side may cause serious action.
                    </p>
                    {/* <!-- <ul className="address">
                  <li><i className="fa fa-map-marker"></i> <span> Address:</span> </li>
                  <li><i className="fa fa-phone"></i> <span> Phone:</span> </li>
                  
                  
                </ul>--> */}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
