import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";
export default function WhoWeR() {
  return (
    <div>
      <Navigation />

      <section id="services">
        <div className="container">
          <div
            className="heading wow fadeInUp"
            data-wow-duration="1000ms"
            data-wow-delay="300ms"
          >
            <div className="row">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h1>Team HP Transcoders</h1>
                <h3>We are educated and professional developers group</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <h3>
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                Together we
              </h3>
              <p>
                Builds a powerful and proficient softwares and websites for
                number of clients around the globe. We are educated
                professionals from different knowledge skill work in a team to
                develop the innovative solutios for you.
              </p>

              <h3>Mission</h3>
              <p>
                From our previous experience and expertise in our working area
                we develop applications those helps the human to make life more
                simple and efficient. Utilizing our knowledge area we learn,
                process and develop kinds of solutions
              </p>
            </div>

            <div className="col-lg-6">
              <p>We offers you,</p>
              <ul>
                <li>7+ yrs expert designers work for you</li>
                <li>10+ yrs expert developers team</li>
                <li>Preventive Training</li>
                <li>Secured Fast hosting from partners</li>
                <li>Secured admin panels</li>
                <li>Innovative ideas and development skill</li>
                <li>12 hrs Day support</li>
                <li>Social product and promotions</li>
                <li>Advertising platform</li>
                <li>Virus free solutions</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footers />
    </div>
  );
}
