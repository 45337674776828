import $ from 'jquery';
import React from "react";
import {useEffect} from 'react';

export default function Footers() {
  useEffect(()=>{
    let d = new Date();
    $('.copyrighty').text(d.getFullYear());
    $('.organization').text("HP Transcoders");
    });
  return (
    <>
      <footer id="footer">
        <div
          className="footer-top wow fadeInUp"
          data-wow-duration="1000ms"
          data-wow-delay="300ms"
        >
          <div className="container text-center">
            <div className="footer-logo">
              <a href="https://www.hptranscoders.com">
                <img
                  className="img-responsive"
                  src="assets/images/logo.png"
                  alt="hp-transcoders-tm-logo"
                />
              </a>
            </div>
            <div className="social-icons">
              <ul>
                <li>
                  <a className="twitter" href="https://twitter.com/HPTranscoders">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="facebook"
                    href="https://www.facebook.com/HpTranscoder/"
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <p>&copy; <span className="copyrighty"></span> <span className="organization"></span>. All rights reserved.</p>
              </div>
              <div className="col-sm-6">
                <p className="pull-right">
                  Powered by <span className="organization"></span>
                  <a href="https://www.hptranscoders.com/"><span classNameName="organization"></span></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
