import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";

export default function Error() {
  return (
    <>
      <Navigation />
      <section id="services">
        <div className="container">
          <div
            className="heading wow fadeInUp"
            data-wow-duration="1000ms"
            data-wow-delay="300ms"
          >
            <div className="row">
              <div className="col-sm-12">
                <center>
                  <img
                    src="assets/images/404.gif"
                    alt="hp-transcoders-page-not-found"
                    width="50%"
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footers />
    </>
  );
}
