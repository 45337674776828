import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";
import QuotationLink from "./component/QuotationLink";
export default function SoftwareDevelopment() {
  return (
    <div>
      <Navigation />
      <section id="services">
        <div className="container">
          <div
            className="heading wow fadeInUp"
            data-wow-duration="1000ms"
            data-wow-delay="300ms"
          >
            <div className="row">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h1>Customized Software Development</h1>
                <h3>We create user friendly and robust software</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <h3>
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                HP Transcoders software development service
              </h3>
              <p>
                We develop customized software and web applications to minimize
                your daily work. Be comfortable our expertise and dynamic
                professional team will assist you. Our software can change your
                way of work to help you in overall all aspects of your business.{" "}
              </p>

              <h3>Looking for change?</h3>
              <p>
                If you are looking for a reliable software development service,
                HP Transcoders will provide you innovative and flexible service.
                Our dedicated team will assist you till the completion of your
                project objectives.
              </p>
            </div>
            <div className="col-lg-4">
              {
                <QuotationLink
                  imageSource="assets/images/slider/thumb/customized-software-developemnt-hp-transcoders.webp"
                  AltText="customized-software-development-in-hp-transcoders"
                  Title="How can we help you?"
                  subTitle="Our software can help you to increase your profit"
                />
              }
            </div>

            <div className="col-lg-4">
              <p>We offers you,</p>
              <ul>
                <li>User friendly GUI</li>
                <li>User friendly and responsive website</li>
                <li>integration with your database</li>
                <li>72 hours Training</li>
                <li>
                  Domain Registration <i>(optional)</i>
                </li>
                <li>
                  Business Email IDs <i>(optional)</i>
                </li>
                <li>Control panel of your website or CMS(optional)</li>
                <li>Database Security of your website</li>
                <li>World’s major three programming languages support.</li>
                <li>
                  SEO Ranking <i>(optional)</i>
                </li>

                <li>
                  Malware Protection <i>(optional)</i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footers />
    </div>
  );
}
