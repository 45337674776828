import React from "react";

export default function Testimonials() {
  const testimonialArray=[
    {
      "Testimonial":"They are professional and handle your idea with care",
      "Who":"Fine Touch Team",
      "Country":"United Kingdom",
     },
     {
      "Testimonial":" HP Transcoders not only produce a wonderful product. They guides you through making the product work for YOU!",
      "Who":"Z+ CyberTech",
      "Country":"Brazil",
     },
     {
      "Testimonial":"Perfect technology partner ",
      "Who":"Swetha Nair",
      "Country":"India",
     },
     {
      "Testimonial":" This guys are creative and professional, working with them is awesome experience",
      "Who":"Mark Johansson",
      "Country":"U. S. A.",
     },
    
  ]
  return (
    <>
      <section id="twitter" className="parallax">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-sm-8 col-sm-offset-2">
                <div className="twitter-icon text-center">
                  <h4>Testimonial</h4>
                </div>
                <div
                  id="twitter-carousel"
                  className="carousel slide"
                  data-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div
                      className="item active wow fadeIn"
                      data-wow-duration="1000ms"
                      data-wow-delay="300ms"
                    >
                      <p>
                        They are professional and handle your idea with care{" "}
                        <p className="whois"><i>Fine Touch Team  (U. K.)</i></p>
                      </p>
                    </div>
                    {
                      testimonialArray.map((testimonial, index)=>{
                        return(
                          <div className="item">
                            <p>
                             {testimonial.Testimonial}
                              <p className="whois"><i>{testimonial.Who}, {testimonial.Country}</i></p>
                            </p>
                          </div>
                        );
                      })
                    }
                  

                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
