
import $ from 'jquery';
import { Route,Switch } from 'react-router-dom';
import Home from './Home';
import Website from './Website';
import Error from './Error';

import DigitalMarketing from './DigitalMarketing';
import SoftwareDevelopment from './SoftwareDevelopment';
import SocialProjects from './SocialProjects';
import Blogs from './Blogs';
import WhoWeR from './WhoWeR';
import Quotation from './Quotation';
import Policy from './Policy';
import Marketer from './Marketer';

function App() {
 
return (<>

    <Switch>
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/home" component={Home}></Route>
      <Route exact path="/website-development-at-hp-transcoders" component={Website}></Route>
      <Route exact path="/digital-marketing-at-hp-transcoders" component={DigitalMarketing}></Route>
      <Route exact path="/software-development-at-hp-transcoders" component={SoftwareDevelopment}></Route>
      <Route exact path="/social-projects-at-hp-transcoders" component={SocialProjects}></Route>
      <Route exact path="/hp-transcoders-who-we-are" component={WhoWeR}></Route>
      <Route exact path="/hp-transcoders-blogs" component={Blogs}></Route>
      <Route exact path="/hp-transcoders-quotation" component={Quotation}></Route>
      <Route exact path="/hp-transcoders-policy" component={Policy}></Route>
      <Route exact path="/harshal-nirbhavane" component={Marketer}></Route>
      
      <Route component={Error}></Route>

    </Switch>
    
    </>
  );
}

export default App;
