import React from "react";
import Sliders from './Sliders';

export default function () {
  
  return (
    <>
      {/* <div className="preloader">
        <i className="fa fa-circle-o-notch fa-spin"></i>
      </div> */}
      <header id="home">
        <Sliders/>
      
       </header>
    </>
  );
}
