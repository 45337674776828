import React from 'react';
import { NavLink } from 'react-router-dom';

export default function QuotationLink(props) {
 
    return (
        <div>
           <h3>{props.Title}</h3>
           <p>{props.subTitle}</p>
           <img alt={props.AltText} src={props.imageSource} className="img-responsive" />
           <NavLink className="btn-primary harsh-btn" to="offer"><strong>Need Quotation ?</strong></NavLink> 
        </div>
    )
}
