
//import $ from 'jquery';
import Headers from './component/Headers';
import Services from './component/Services';
import About from './component/About';
import Portfolio from './component/Portfolio';
import Features from './component/Features';
import Testimonials from './component/Testimonials';
import Blogs from './component/Blogs';
import Contact from './component/Contact';
import Footers from './component/Footers';
import Navigation from './component/Navigation';

// import {useEffect} from 'react';

function Home() {
//  useEffect(()=>{
//  //alert("hi");  
//  let d = new Date();
//  $('.copyrighty').text(d.getFullYear());
 
//  $('.organization').text("HP Transcoders");
//  });
return (<>

    <Headers/>
    <Navigation/>
    <Services/>
    <About/>
    <Portfolio/>
    <Features/>
    <Testimonials/>
    <Blogs/>
    <Contact/>
    <Footers/>
    
    </>
  );
}

export default Home;
