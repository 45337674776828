import "./App.css";
import { Helmet } from "react-helmet";
function Marketer() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Digital Marketing Professional Harsh</title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta
          content="Innovation and creative graphics, web designer, developer and digital marketing professional. 8+ years of experience in field and having goal oriented approach towards the solution"
          name="description"
        />
        <meta content="innovative web developer" name="keywords" />
        <link href="i_assets/img/favicon.png" rel="icon" />
        <link href="i_assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Digital Marketing Professional Harsh"
        />
        <meta
          property="og:description"
          content="Innovation and creative graphics, web designer, developer and digital marketing professional. 8+ years of experience in field and having goal oriented approach towards the solution"
        />
        <meta
          property="og:image"
          content="http://www.hptranscoders.com/i_assets/img/dm1.webp"
        />
        <meta
          name="twitter:image"
          content="http://www.hptranscoders.com/i_assets/img/dm1.webp"
        />
        <meta
          property="og:url"
          content="http://www.hptranscoders.com/harshal-nirbhavane"
        ></meta>
      </Helmet>
      <iframe src="https://harsh-df68e.web.app/" width="100%" height={800} />
    </>
  );
}
export default Marketer;
