import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";
export default function Policy() {
  return (
    <div>
      <section id="services">
        <div className="container">
          <div
            className="heading wow fadeInUp"
            data-wow-duration="1000ms"
            data-wow-delay="300ms"
          >
            <div className="row">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h2>Privacy Policy</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-lg-12 wow fadeInDown"
              data-wow-duration="1000ms"
              data-wow-delay="300ms"
            >
              <div className="col-sm-12">
                <ul className="list">
                  <li>
                    The contents hereof shall constitute The HP Transcoders
                    privacy policy in relation to the Service on this Website
                    (“Privacy Policy”). Capitalized terms not defined herein
                    shall have the meaning ascribed to them in the User
                    Agreement or the Partner Agreement, as the case may be.{" "}
                  </li>
                  <li>
                    We do not sell or rent your personal information to third
                    parties for their marketing purposes without your explicit
                    consent but may use it for our internal study and analysis
                    purposes such as consumer behavior, your preferences,
                    popularity of the product etc. We understand clearly that
                    you and Your Information is one of our most important
                    assets. We store and process Your Information on computers
                    that are protected by physical as well as technological
                    security devices. We keep Your Information on record and
                    facilitate its availability to you as and when applicable to
                    the use of the product purchased by you. On the expiry or
                    termination of the Terms and Conditions or suspension of
                    Your Account, HP Transcoders is in no way obligated to
                    continue to make available Your Information to you. We use
                    third parties to verify and certify our privacy principles.
                    If you object to Your Information being transferred or used
                    in this way please do not use this Website.{" "}
                  </li>
                  <li>
                    By visiting our website third parties may place cookies on
                    your browsers for targeted advertising purposes. This may
                    include collecting IP addresses, cookie identifiers, and
                    some website activity for the purpose targeted advertising
                    by HP Transcoders. This data may be used by third parties to
                    target advertising on other sites based on your online
                    activity.{" "}
                  </li>
                  <li>
                    We will protect Your Information in accordance with our
                    Privacy Policy (detailed herein). If you use this Website,
                    you are responsible for maintaining the confidentiality of
                    Your Account and password created under Your Account
                    (“Password”) and for restricting access to your computer,
                    and you agree to accept responsibility for all activities
                    that occur under Your Account. You agree to take due care in
                    protecting your username and Password against misuse by
                    others and promptly notify HP Transcoders about any misuse.
                    If HP Transcoders has reason to believe that there is likely
                    to be a breach of security or misuse of the Website, HP
                    Transcoders may require you to change your Password or may
                    suspend Your Account without any liability to this Website
                    or to HP Transcoders.{" "}
                  </li>
                  <li>
                    HP Transcoders shall not be responsible for removal of the
                    Comments, Your Information or any other contents from the
                    Website/Service when your registration is terminated.{" "}
                  </li>
                  <li>
                    HP Transcoders does not warrant that our site, server, or
                    emails sent from us are free of viruses or other harmful
                    effects. You explicitly comply and agree that your use of
                    the Website is at your own individual risk.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
