import React from 'react';
import logo from './../dependant_image/logo.png' ;
import { NavLink } from 'react-router-dom';

export default function Navigation() {
    return (
        <>
        { 
        /* <div className="main-nav">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="home">
                <h1>
                 
                  <img
                    className="img-responsive"
                    src="assets/images/logo.png"
                    alt="HP Transcoders"
                  />
                </h1>
              </a>
            </div>
            <div className="collapse navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li className="scroll">
                  <a href="/">Home</a>
                </li>
                <li className="scroll">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Service <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="scroll">
                      <a href="digital-marketing">Digital-marketing</a>
                    </li>
                    <li className="scroll">
                      <a href="website-development">Website Developement</a>
                    </li>
                    <li className="scroll">
                      <a href="customized-software-development">
                        Software Development
                      </a>
                    </li>

                    <li className="scroll">
                      <a href="social-project">Social Projects</a>
                    </li>
                  </ul>
                </li>
                <li className="scroll">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    About Us <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="scroll">
                      <a href="hp-transcoders-team">Who we are?</a>
                    </li>
                    <li className="scroll">
                      <a href="#portfolio">Portfolio</a>
                    </li>
                    <li className="scroll">
                      <a href="hp-transcoders-blog">blog</a>
                    </li>
                  </ul>
                </li>

                <li className="scroll">
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
       */}
       
       <div className="main-nav">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <NavLink className="navbar-brand" to="home">
                <h1>
                 
                  <img
                    className="img-responsive"
                    src={logo}
                    alt="HP Transcoders"
                  />
                </h1>
              </NavLink>
            </div>
            <div className="collapse navbar-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li className="scroll">
                  {/* <NavLink to="/">Home</NavLink> */}
                  <a href="/">Home</a>
                </li>
                <li className="scroll">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Services <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="scroll">
                      <NavLink to="digital-marketing-at-hp-transcoders">Digital-marketing</NavLink>
                    </li>
                    <li className="scroll">
                      <NavLink to="website-development-at-hp-transcoders">Website Developement</NavLink>
                    </li>
                    <li className="scroll">
                      <NavLink to="software-development-at-hp-transcoders">
                        Software Development
                      </NavLink>
                    </li>

                    <li className="scroll">
                      <NavLink to="social-projects-at-hp-transcoders">Social Projects</NavLink>
                    </li>
                  </ul>
                </li>
                <li className="scroll">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    About Us <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="scroll">
                      <NavLink to="hp-transcoders-who-we-are">Who we are?</NavLink>
                    </li>
                    {/* <li className="scroll">
                      <NavLink to="home#portfolio">Portfolio</NavLink>
                    </li> */}
                    <li className="scroll">
                      <NavLink to="hp-transcoders-blogs">blog</NavLink>
                    </li>
                  </ul>
                </li>

                <li className="scroll">
                  <a href="/#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      
        </>
    )
}
