import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";
import QuotationLink from "./component/QuotationLink";

export default function Website() {
  return (
    <div>
      <Navigation />
 
      <section id="services">
    <div className="container">
      <div className="heading wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
        <div className="row">
          <div className="text-center col-sm-8 col-sm-offset-2">
            <h1>Website Developement</h1>
            <h3>We develop fully professional, beautiful and secured websites</h3>
          </div>
        </div> 
      </div>
      
      <div className="row">
        <div className="col-lg-4">
          <h3><i className="fa fa-quote-left" aria-hidden="true"></i>
We Know</h3>
          <p>Website is a mirror of your business, it show a quality of service you provide to your consumers. Our satisfied clients is a key of our success, you can share your thoughts about your website with us. We offer you different packages according to your business needs.</p>

          <h3>Beautiful Design</h3>
           <p>Website is a mirror of your business, it show a quality of service you provide to your consumers. Our satisfied clients is a key of our success, you can share your thoughts about your website with us. We offer you different packages according to your business needs.</p>
        </div>
        <div className="col-lg-4">
         
           {
                <QuotationLink
                  imageSource="assets/images/slider/thumb/website-development-company-hp-transcoders.webp"
                  AltText="website-development-in-hp-transcoders"
                  Title="How can we help you?"
                  subTitle="Our website developers team is fully professional in their working"
                />
              }
        </div>
       <div className="col-lg-4">
            <p>We offers you,</p>
          <ul>
            <li>Beautiful and brand new Website</li>
            <li>User friendly and responsive website</li>
            <li>24 x 7 hours supports</li>
            <li>Fast and secure web hosting</li>
            <li>Domain Registration</li>
            <li>Business Email IDs</li>
            <li>Control panel or CMS<i>(optional)</i></li>
            <li>Secured database</li>
            <li>World’s major three programming languages support.</li>
            <li>SEO Ranking <i>(optional)</i></li>
            <li>Advertising through our search engine <i>(optional)</i></li>
            <li>Virus Protection</li>
            </ul>
        </div>
      </div>
    </div>
  </section>
 

      <Footers />
    </div>
  );
}
