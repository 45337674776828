import React from "react";

export default function Portfolio() {
  const product = [
    {
      Name: "RMS",
      Description: "Business Edition",
      Image: "assets/images/portfolio/1.webp",
      AltText: "hp-transcoders-rms-business-edition",
    },

    {
      Name: "Ek Shodh",
      Description: "Advanced Local Search Engine",
      Image: "assets/images/portfolio/2.webp",
      AltText: "advanced-local-search-engine-ek-shodh",
    },
    {
      Name: "eCommerce Application Development",
      Description: "ECommerce",
      Image: "assets/images/portfolio/3.webp",
      AltText: "hp-transcoders-ecommerce-application-development",
    },
    {
      Name: "Customized Website",
      Description: "Matrimony Web Application",
      Image: "assets/images/portfolio/4.webp",
      AltText: "hp-transcoders-customized-matrimony-website",
    },
    {
      Name: "1Shodh Store",
      Description: "Online Shopping Cart",
      Image: "assets/images/portfolio/5.webp",
      AltText: "ek-shodh-online-shopping-website",
    },
    {
      Name: "CMS Solution",
      Description: "Dealer's ERP Sytem",
      Image: "assets/images/portfolio/6.webp",
      AltText: "hp-transcoders-cms-solution",
    },
    {
      Name: "1Shodh App",
      Description: "Android App",
      Image: "assets/images/portfolio/7.webp",
      AltText: "ek-shodh-android-app",
    },
    {
      Name: "BM4u",
      Description: "General ERP",
      Image: "assets/images/portfolio/8.webp",
      AltText: "bm4u-hp-transcoders-general-erp",
    },
  ];
  return (
    <>
      <section id="portfolio">
        <div className="container">
          <div className="row">
            <div
              className="heading text-center col-sm-8 col-sm-offset-2 wow fadeInUp"
              data-wow-duration="1000ms"
              data-wow-delay="300ms"
            >
              <h2>Our Portfolio</h2>
              <p>
                Our portfolio increases everyday, just look here for our popular
                work
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            {product.map((products, index) => {
              return (
                <div className="col-sm-3">
                  <div
                    className="folio-item wow fadeInRightBig"
                    data-wow-duration="1000ms"
                    data-wow-delay="300ms"
                  >
                    <div className="folio-image">
                      <img
                        className="img-responsive"
                        src={products.Image}
                        alt={products.AltText}
                      />
                    </div>
                    <div className="overlay">
                      <div className="overlay-content">
                        <div className="overlay-text">
                          <div className="folio-info">
                            <h3>{products.Name}</h3>
                            <p>{products.Description}</p>
                          </div>
                          <div className="folio-overview">
                            <span className="folio-expand">
                              <a
                                href={products.Image}
                                data-lightbox="portfolio"
                                alt={products.AltText}
                              >
                                <i className="fa fa-search-plus"></i>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div id="portfolio-single-wrap">
          <div id="portfolio-single"></div>
        </div>
      </section>
    </>
  );
}
