import React from "react";

export default function Services() {
  const servicesArray = [
    {
      ServiceName: "Digital Marketing",
      ServiceDescription:
        "We promote your business services or products through digital channels like social media, search engine, mobile applications, websites or new upcoming related technologies and generate great relevant leads for your business.",
      ServiceIcon: "fa fa-bullhorn",
    },
    {
      ServiceName: "Graphics Designing",
      ServiceDescription:
        "We know graphics speaks more than the word. We will create beautiful and interactive artwork and graphics thant ensure a higher conversion through branding",
        ServiceIcon: "fa fa-paint-brush",
      },
    {
      ServiceName: "Web designing",
      ServiceDescription:
        "We believe beautiful website with interactive content is a key to start your first communication with your clients. We design the beautiful website with considering all future digital marketing activities that you can extends it in future.",
      ServiceIcon: "fa fa-globe fa-spin",
    },
    {
      ServiceName: "Customized Software Solutions",
      ServiceDescription:
        "With help of your requirements and our expertise, we develop professional software applications. We can work on all platform as per requirement.",
      ServiceIcon: "fa fa-cog fa-spin",
    },
    {
      ServiceName: "Website development",
      ServiceDescription:
        "We develop fully professional and responsive web application for all platform. We know website is a first impression of your business to your clients.  ",
      ServiceIcon: "fa fa-spinner fa-spin",
    },
    {
      ServiceName: "Support",
      ServiceDescription:
        "We are happy to give you support after product delivery only expected, you will satisfy with our service.",
      ServiceIcon: "fa fa-briefcase",
    },
  ];
  return (
    <>
      <section id="services">
        <div className="container">
          <div
            className="heading wow fadeInUp"
            data-wow-duration="1000ms"
            data-wow-delay="300ms"
          >
            <div className="row">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h2>Our Services</h2>
                <p>
                  We are happy to provide you fully professional and powerful
                  solutions for your business. Look here..
                </p>
              </div>
            </div>
          </div>
          <div className="text-center our-services">
            <div className="row">
              {servicesArray.map((item, index) => {
                return (
                  <div
                    className="col-sm-4 wow fadeInDown"
                    data-wow-duration="1000ms"
                    data-wow-delay="300ms"
                  >
                    <div className="service-icon">
                      <i className={item.ServiceIcon}></i>
                    </div>
                    <div className="service-info">
                      <h3>{item.ServiceName}</h3>
                      <p>{item.ServiceDescription}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
