import React from "react";

export default function Sliders() {
  const sliderArray = [
    {
      SliderImage: "assets/images/slider/1.webp",
      SliderHeader: "",
      SliderSubHeader: "",
      SliderButton: "Click Here",
    },
    {
      SliderImage: "assets/images/slider/2.webp",
      SliderHeader: "Lead Generation Solution",
      SliderSubHeader: "",
      SliderButton: "Work with us",
    },
    {
      SliderImage: "assets/images/slider/3.webp",
      SliderHeader: "Creative Idea for Marketing",
      SliderSubHeader: "",
      SliderButton: "Start now",
    },
  ];
  return (
    <>
      <div
        id="home-slider"
        className="carousel slide carousel-fade"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          <div
            className="item active"
            style={{ "backgroud-image": "url(assets/images/slider/2.webp)" }}
          >
            <div className="caption">
              <h1 className="animated fadeInLeftBig">
                Life with <span>Technology</span>
              </h1>
              <p className="animated fadeInRightBig">
                We make your business reliable and smart
              </p>
              <a
                data-scroll
                className="btn btn-start animated fadeInUpBig"
                href="#services"
              >
                How ?
              </a>
            </div>
          </div>
          {sliderArray.map((slide, index) => {
            return (
              <div
                className="item"
                style={{
                  "background-image": "url("+ slide.SliderImage+")"
                }}
              >
                <div className="caption">
                  <h1 className="animated fadeInLeftBig">
                    {slide.SliderHeader}
                  </h1>
                 <p className="animated fadeInRightBig">
                  {slide.SliderSubHeader}
                </p>
                  <a
                    data-scroll
                    className="btn btn-start animated fadeInUpBig"
                    href="#services"
                  >
                   {slide.SliderButton}
                  </a>
                </div>
              </div>
            );
          })}
          
        </div>
        <a className="left-control" href="#home-slider" data-slide="prev">
          <i className="fa fa-angle-left"></i>
        </a>
        <a className="right-control" href="#home-slider" data-slide="next">
          <i className="fa fa-angle-right"></i>
        </a>

        <a id="tohash" href="#services">
          <i className="fa fa-angle-down"></i>
        </a>
      </div>
    </>
  );
}
