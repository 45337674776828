import React from 'react'
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";

import QuotationLink from './component/QuotationLink';
export default function SocialProjects() {
    return (
        <div>
            <Navigation/>
            
 <section id="services">
    <div className="container">
      <div className="heading wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms">
        <div className="row">
          <div className="text-center col-sm-8 col-sm-offset-2">
            <h1>Social Project</h1>
            <h3>We develop fully professional, beautiful, secured websites and applications in affordable rate</h3>
          </div>
        </div> 
      </div>
      
      <div className="row">
        <div className="col-lg-4">
          <h3><i className="fa fa-quote-left" aria-hidden="true"></i>
Think social</h3>
          <p>If you are interested to work for society, we are ready to assist you in a development of kinds of application in cheaper rate that help you to get an objective.</p>

          <h3>Beautiful Design</h3>
           <p>Website is a mirror of your business, it show a quality of service you provide to your consumers. Now tell more about your organization online</p>
        </div>
        <div className="col-lg-4">
          {/* <h3>How can we help you?</h3>
          <p>We can develop your social product in affordable rate</p>
           <img alt="social-website-development-in-hp-transcoders" src="assets/images/slider/thumb/social-website-development-in-hp-transcoders.webp" className="img-responsive" />
         
          <NavLink className="btn-primary harsh-btn " to="offer"><strong>Need Quotation ?</strong></NavLink> */}
           {
             <QuotationLink 
             imageSource="assets/images/slider/thumb/social-website-development-in-hp-transcoders.webp" 
             AltText="social-website-development-in-hp-transcoders"
             Title="How can we help you?"
             subTitle="We can develop your social product in affordable rate"
             />
           }
           
        </div>

        
        <div className="col-lg-4">
        
            <p>We offers you,</p>
          <ul >

            <li>Beautiful and brand new UI</li>
            <li>User friendly and responsive design</li>
          
            <li>Fast and secure web hosting</li>
            <li>Domain Registration</li>
            <li>Business Email IDs</li>
            <li>Control panel of your website or CMS(optional)</li>
            <li>Database Security </li>
            <li>World’s major three programming languages support.</li>
            <li>SEO Ranking (optional)</li>
            <li>Advertising through our search engine (optional)</li>
            <li>Malware Protection</li></ul>

           
        </div>

      </div>
    </div>
  </section>
  <Footers/>

        </div>

    )
}
