import React from "react";
import { NavLink } from 'react-router-dom';

export default function Blogs() {
  const blogArray=[
    {
      "BlogLink":"/digital-marketing-at-hp-transcoders",
      "BlogImage":"assets/images/blog/1.webp",
      "BlogAlt":"promote-your-business-online-hptranscoders-digital-marketing-tips",
      "BlogTitle":"HP Transcoders digital marketing service helps you to promote your business online",
      "Category":"Business Tips",
      "EntryContent":"Are you interested to promote your business? How digital marketing techniques help you to grow your business? Every business need promotions to catch customers attention.."
    },
    {
      "BlogLink":"/website-development-at-hp-transcoders",
      "BlogImage":"assets/images/slider/thumb/website-development-company-hp-transcoders.webp",
      "BlogAlt":"promote-your-business-online-hptranscoders-website-designing-tips",
      "BlogTitle":"Create your website and promote your business online",
      "Category":"Business Tips",
      "EntryContent":"Are you interested to promote your business? How digital marketing techniques help you to grow your business? Every business need promotions to catch customers attention.."
    },
    {
      "BlogLink":"/digital-marketing-at-hp-transcoders",
      "BlogImage":"assets/images/blog/1.webp",
      "BlogAlt":"promote-your-business-online-hptranscoders-digital-marketing-tips",
      "BlogTitle":"Promote your business online",
      "Category":"Business Tips",
      "EntryContent":"Are you interested to promote your business? How digital marketing techniques help you to grow your business? Every business need promotions to catch customers attention.."
    },
    
  ]
  return (
    <>
      <section id="blog">
        <div className="container">
          <div className="row">
            <div
              className="heading text-center col-sm-8 col-sm-offset-2 wow fadeInUp"
              data-wow-duration="1200ms"
              data-wow-delay="300ms"
            >
              <h2>Blog Posts</h2>
              <p>See what we share</p>
            </div>
          </div>
          <div className="blog-posts">
            <div className="row">
              {
                blogArray.map((blog,index)=>{
                  return(
                    <div
                className="col-sm-4 wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="400ms"
              >
                <div className="post-thumb">
                  <NavLink to={blog.BlogLink}>
                    <img
                      className="img-responsive"
                      src={blog.BlogImage}
                      alt={blog.BlogAlt}
                    />
                  </NavLink>
                  <div className="post-meta"></div>
                </div>
                <div className="entry-header">
                  <h3>
                    <NavLink to={blog.BlogLink}>
                      {blog.BlogTitle}
                    </NavLink>
                  </h3>
                 
                  <span className="cetagory">
                    in <strong>{blog.Category}</strong>
                  </span>
                </div>
                <div className="entry-content">
                  <p>
                    {blog.EntryContent}
                  </p>
                </div>
              </div>
             
                  );
                })
              }
              </div>
          </div>
        </div>
      </section>
    </>
  );
}
