import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";
export default function DigitalMarketing() {
  return (
    <div>
      <Navigation />

      <section id="services" className="dmarticle">
        <div className="container">
          <div
            className="heading wow fadeInUp"
            data-wow-duration="1000ms"
            data-wow-delay="300ms"
          >
            <div className="row">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h1>How digital marketing help to increase business leads?</h1>
                <h3>Simple techniques that every business owner must know</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <h3>What is digital marketing?</h3>
              <p>
                A simple answer is promoting your business services or products
                through digital channels like social media, search engine,
                mobile applications, websites or new upcoming related
                technologies that involves electronic devices.
              </p>

              <h3>What are benefits of digital marketing?</h3>
              <ul>
                <li>
                  It will help to create brand awareness and engagement with
                  audience after sales and service.
                </li>
                <li>
                  It will help you to convert new buyers into your repetitive
                  buyer who buy more.
                </li>
                <li>
                  It will help to increase higher conversion ratio of selling.
                </li>
                <li>
                  It will help to minimize the buyers journey from begin to
                  purchase to end with sale.
                </li>
                <li>
                  It will save cost of budget for expenses on advertisement.
                </li>
              </ul>

              <h3>Why digital marketing popular today?</h3>
              <p>Consider an advertise on billboard, and think</p>
              <ul>
                <li>How many people looking your products advertise?</li>
                <li>
                  Is billboard on right location to catch attention of people
                  who pass around it?
                </li>
                <li>Are right people looks my billboard?</li>
                <li>
                  How many chances of conversion to successful deal through
                  billboard?
                </li>
                <li>How much you pay for billboard ads?</li>
              </ul>
              <p>
                It totally depends on the number of persons who passing near to
                it and how many just notice your billboard.
              </p>
              <p>
                The same scenario about traditional marketing, they just
                broadcasting but less interacting with customers and ultimately
                lower conversion ratio at end.
              </p>
            </div>
            <div className="col-lg-8">
              <div className="col-lg-12">
                <h3 style={{"text-align": "center", "background-color": "#558ff2", "padding": "10px", "color":"white"}}>
                  Techniques uses for digital marketing
                </h3>
              </div>
              <div className="col-lg-6">
                <h3>Search engine marketing (SEM)</h3>
                <p>
                  Consider Google, approx 90% of your prospects used Google
                  platforms every day. Think if your business is highlighted by
                  Google to your prospect when they looking for services like
                  you offered, you will gain more traffic towards your websites
                  and phones.
                </p>

                <h3>Search engine optimization (SEO)</h3>
                <p>
                  It’s a technique to develop resources for search engine that
                  will get higher rank under SERP while user entered his query
                  in search engine. Higher effort required to make a SEO of any
                  website, video, image, apps to rank at top from large amount
                  of resources. It based on the search engine algorithms and
                  will changes frequently.
                </p>

                <h3>Content marketing</h3>
                <p>
                  People said content is a king! Its right statement but
                  relevant content is necessity to interaction with your
                  customers. Unnecessary content of a subject may increase
                  bounce rate of interaction. You have lot of choices like
                  relevant graphics, artworks, blogs, websites that will help
                  your customers to reach you.
                </p>
                <p>
                  Graphics speaks more than word. A good artwork for advertise
                  not only attract the attention but also will cause to
                  interaction.{" "}
                </p>

                <h3>Pay-per-click advertising (PPC)</h3>
                <p>
                  It’s a strategy to adverting simply meaning you can pay only
                  if someone will click on your advertisement. Depends on your
                  market analysis and goal of advertisement campaign you can use
                  it and take its benefits.
                </p>
              </div>
              <div className="col-lg-6">
                <h3>Social Media Marketing (SMM)</h3>
                <p>
                  Now in lockdown because of the COVID 19, if you study the
                  consumption of internet data is higher and guesses how many
                  people interact every day with social media except their
                  working time. From couple of years study you definitely
                  understand people also spent their valuable time on social
                  media like YouTube, facebook, LinkedIn etc.
                </p>
                <p>
                  Facebook also give you opportunity to promote your business on
                  their platform. Remember the sponsored ads while you checkout
                  your facebook post. It get an idea how it help you.
                </p>

                <h3>Affiliate marketing</h3>
                <p>
                  You can earn money by promoting ecommerce websites products.
                  Today you can find many affiliate websites who redirects you
                  to websites like Amazon, Flipkart etc.
                </p>

                <h3>Email marketing</h3>
                <p>
                  An email is the real hero behind the scene, your customers
                  like a human touch but digitally. Do you remember some of
                  emails in your inbox that could be your shopping purchase,
                  greeting from shop, paid webinar news announcement, new offers
                  at your favorite shopping portal etc. these are some examples.
                  Now you understand your inbox is now a place of advertiser’s
                  promotion platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footers />
    </div>
  );
}
