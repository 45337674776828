import React from "react";
export default function About() {
  
  
  return (
    <>
      <section id="about-us" className="parallax">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div
                className="about-info wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="300ms"
              >
                <h2>About us</h2>
                <p>
                  We are the professional developers group interested to provide
                  dynamic and reliable services to our consumers. We innovate
                  new technology to overcome drawback of available technology in
                  market.
                </p>
                <p>
                  We believe on technology and expect to make human life more
                  simple, interesting through our products and services.
                </p>
                <p>
                  We works on your project from beginning to end of development
                  of highly reliable solution.Don't worry! We have lifetime
                  support for you as required.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div
                className="single-skill wow fadeInDown"
                data-wow-duration="1000ms"
                data-wow-delay="600ms"
              >
                <p className="lead">Digital Marketing</p>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-primary six-sec-ease-in-out"
                    role="progressbar"
                    aria-valuetransitiongoal="98"
                 
                  >
                    98% 
                  </div>
                </div>
              </div>
              <div
                className="our-skills wow fadeInDown"
                data-wow-duration="1000ms"
                data-wow-delay="300ms"
              >
                <div
                  className="single-skill wow fadeInDown"
                  data-wow-duration="1000ms"
                  data-wow-delay="300ms"
                >
                  <p className="lead">User Experiances</p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-primary six-sec-ease-in-out"
                      role="progressbar"
                      aria-valuetransitiongoal="95"
                    >
                      95%
                    </div>
                  </div>
                </div>
                <div
                  className="single-skill wow fadeInDown"
                  data-wow-duration="1000ms"
                  data-wow-delay="400ms"
                >
                  <p className="lead">Web Development</p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-primary six-sec-ease-in-out"
                      role="progressbar"
                      aria-valuetransitiongoal="95"
                    >
                      95%
                    </div>
                  </div>
                </div>
                <div
                  className="single-skill wow fadeInDown"
                  data-wow-duration="1000ms"
                  data-wow-delay="500ms"
                >
                  <p className="lead">Programming</p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-primary six-sec-ease-in-out"
                      role="progressbar"
                      aria-valuetransitiongoal="98"
                    >
                      98%
                    </div>
                  </div>
                </div>
                <div
                  className="single-skill wow fadeInDown"
                  data-wow-duration="1000ms"
                  data-wow-delay="600ms"
                >
                  <p className="lead">Support</p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-primary six-sec-ease-in-out"
                      role="progressbar"
                      aria-valuetransitiongoal="98"
                    >
                      98%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
