import React from "react";
import Navigation from "./component/Navigation";
import Footers from "./component/Footers";
export default function Blogs() {
  return (
    <div>
      <Navigation />

      <section id="blog">
        <div className="container">
          <div className="row">
            <div
              className="heading text-center col-sm-8 col-sm-offset-2 wow fadeInUp"
              data-wow-duration="1200ms"
              data-wow-delay="300ms"
            >
              <h2>Blog Posts</h2>
              <p>See what we share</p>
            </div>
          </div>
          <div className="blog-posts">
            <div className="row">
              <div
                className="col-sm-4 wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="400ms"
              >
                <div className="post-thumb">
                  <a href="#">
                    <img
                      className="img-responsive"
                      src="assets/images/blog/1.webp"
                      alt="blog-1"
                    />
                  </a>
                  <div className="post-meta">
                    <span>
                      <i className="fa fa-heart"></i> 5,914 Likes
                    </span>
                  </div>
                </div>
                <div className="entry-header">
                  <h3>
                    <a
                      href="http://www.1shodh.com/article/advertise-your-business-online"
                      target="_blank"
                    >
                      Promote your business | Simple steps
                    </a>
                  </h3>
                  <span className="date">March 19, 2020</span>
                  <span className="cetagory">
                    in <strong>Business Tips</strong>
                  </span>
                </div>
                <div className="entry-content">
                  <p>
                    Are you interested to promote your business? Every business
                    need promotions to catch customers in market.{" "}
                  </p>
                </div>
              </div>
              <div
                className="col-sm-4 wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="600ms"
              >
                <div className="post-thumb">
                  <div
                    id="post-carousel"
                    className="carousel slide"
                    data-ride="carousel"
                  >
                    <ol className="carousel-indicators">
                      <li
                        data-target="#post-carousel"
                        data-slide-to="0"
                        className="active"
                      ></li>
                      <li data-target="#post-carousel" data-slide-to="1"></li>
                      <li data-target="#post-carousel" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                      <div className="item active">
                        <a href="#">
                          <img
                            className="img-responsive"
                            src="assets/images/blog/2.webp"
                            alt="blog-2"
                          />
                        </a>
                      </div>
                      <div className="item">
                        <a href="#">
                          <img
                            className="img-responsive"
                            src="assets/images/blog/1.webp"
                            alt="blog-1"
                          />
                        </a>
                      </div>
                      <div className="item">
                        <a href="#">
                          <img
                            className="img-responsive"
                            src="assets/images/blog/3.webp"
                            alt="blog-3"
                          />
                        </a>
                      </div>
                    </div>
                    <a
                      className="blog-left-control"
                      href="#post-carousel"
                      role="button"
                      data-slide="prev"
                    >
                      <i className="fa fa-angle-left"></i>
                    </a>
                    <a
                      className="blog-right-control"
                      href="#post-carousel"
                      role="button"
                      data-slide="next"
                    >
                      <i className="fa fa-angle-right"></i>
                    </a>
                  </div>
                  <div className="post-meta">
                    <span>
                      <i className="fa fa-heart"></i> 10,921 Likes
                    </span>
                  </div>
                </div>
                <div className="entry-header">
                  <h3>
                    <a href="http://www.1shodh.com/articles/" target="_blank">
                      Social Articles{" "}
                    </a>
                  </h3>
                  <span className="date">MAY 16, 2014</span>
                  <span className="cetagory">
                    in <strong>Social Articles</strong>
                  </span>
                </div>
                <div className="entry-content">
                  <p>
                    We educate people to learn new things in technology to make
                    their life enjoyable, secured and simple.{" "}
                  </p>
                </div>
              </div>
              <div
                className="col-sm-4 wow fadeInUp"
                data-wow-duration="1000ms"
                data-wow-delay="800ms"
              >
                <div className="post-thumb">
                  <a href="#">
                    <img
                      className="img-responsive"
                      src="assets/images/blog/3.webp"
                      alt="blog-3"
                    />
                  </a>
                  <div className="post-meta">
                    <span>
                      <i className="fa fa-heart"></i> 985 Likes
                    </span>
                  </div>
                </div>
                <div className="entry-header">
                  <h3>
                    <a
                      href="http://www.1shodh.com/article/the-art-to-defeat-hacker"
                      target="_blank"
                    >
                      How hacker find your details
                    </a>
                  </h3>
                  <span className="date">AUG 11, 2014</span>
                  <span className="cetagory">
                    in <strong>General Awarness</strong>
                  </span>
                </div>
                <div className="entry-content">
                  <p>
                    You submitted your all personal details inside the resume.
                    Terrorist or criminals create an identity or legal document
                    according to your information.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footers />
    </div>
  );
}
